.person-card{
    display: flex;
    flex-direction: column;
}

.card-text-content{
    padding: 1rem;
    display: flex;
    flex-direction: column;
    gap:1rem;
    border-radius: 0.5rem;
    min-height: 12rem;
    margin-top: -1rem;
}
.person-name{
    font-size: 2rem;
}

.person-domain{
    font-size: 1.5rem;
}

.person-name, .person-domain{
    color:black;
    font-weight: 400;
}

.person-title{
    color: black;
    font-weight: 800;
    font-size: 1.5rem;
}

.card-text-content, .person-name, .person-domain, .person-title{
    background-color: #CCCCCC;
}

@media (max-width: 600px){
    .person-name{
        font-size: 1rem;
    }

    .person-title{
        font-size: 1rem;
    }

    .card-text-content{
        min-height: 8rem;
        
    }
    

}