.image-main{
    max-height: 100%;
    max-width: 100%;
    border-radius: 0.5rem;
    object-fit: contain;
}

@media (max-width: 600px) {
    .image-main{
        /* max-height: 100%; */
        max-width: 100%;
        border-radius: 1rem;
        object-fit: cover;
        
    }
}