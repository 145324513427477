.navbar{
    margin-top: 2rem;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.brand-title{
    font-weight: 800;
}

.nav-link{
    text-decoration: none;
    padding: 0.5rem;
    display: block;
}

.navbar-items-right ul{
    display: flex;
    gap: 1rem;
}

.navbar-items-right li{
    list-style: none;
}

.toggle-button{
    position: absolute;
    top: 2rem;
    right: 0rem;
    display: none;
    flex-direction: column;
    justify-content: space-between;
    width: 2rem;
    height: 2rem;
}

.toggle-button .bar{
    height: 3px;
    width: 100%;
    background-color: #006633;
    border-radius: 10px;
}

@media (max-width: 600px) {
    .toggle-button{
        display: flex;
    }
    .navbar-items-right{
        display: none;
        width: 100%;
    }
    .navbar{
        flex-direction: column;
        align-items: flex-start;
    }
    .navbar-items-right ul{
        width: 100%;
        flex-direction: column;
    }
    .navbar-items-right.active{
        display: flex;
    }
    .navbar-items-left{
        margin-right: 2rem;
    }
}