.inner{
    margin-top: 2rem;
    margin-left: 1rem;
    margin-right: 1rem;
    margin-bottom: 2rem;
    display: flex;
    flex-direction: column;
    gap: 4rem;
}

