.custom-btn{
    text-align: center;
    /* border: 2px solid var(--white); */
    padding: 1rem;
    border-radius: 1rem;
    cursor: pointer;
    background-color: var(--light_green);
    font-size: 1.2rem;
    font-weight: 200;
    /* width: 70%; */
    text-align: center;
    color: black;
    font-weight: 400;
}


.custom-btn:hover{
    background-color: var(--white);
    color: var(--theme);
    transition: all 0.2s;
}