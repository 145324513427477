/* @import url('https://fonts.googleapis.com/css2?family=Outfit:wght@200;300;400;700;800&display=swap');
 */
 @import url('https://fonts.googleapis.com/css2?family=Outfit:wght@200;300;400;700;800&family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap');

:root{
    /* --theme:#051231; */
    /* --white:#fff; */
    /* --theme:#99cc66; */
    --theme:#fff;
    --white:#006633;
    --light_green:#99cc66;
    --black:#000000;
}

*{
    box-sizing: border-box;
    background-color: var(--theme);
    color:var(--white);
    font-family: 'Roboto';
    padding: 0;
    margin: 0;
    
}

.input-wrapper{
    margin: 0 auto;
    display: flex;
    flex-direction: column;
    justify-content: center;
    text-align: center;
    gap: 2rem;
    width: 70%;
}

.header-text{
    font-size: 2.3rem;
    font-weight: 800;
}

.secondary-header-text{
    font-size: 2rem;
    font-weight: 500;
}

.para-text{
    font-size: 1.5rem;
    font-weight: 300;

}

@media (max-width: 600px){
    .input-wrapper{
        width: 100%;
        /* display: block; */
    }

    .para-text{
        font-size: 1rem;
        font-weight: 300;
    
    }
}