/* .about{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 5rem;
} */

.header-about{
    margin-top: 1.2rem;
}

.card-grid{
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    row-gap: 5%;
    column-gap: 10%;
}

@media (max-width: 600px){
    .about{
        display: flex;
        flex-direction: column;
        gap: 2rem;
    }

    .card-grid{
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        row-gap: 1%;
        column-gap: 10%;
        
    }
}