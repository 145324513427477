.hero-section{
    margin: 4rem 0rem;
    display: flex;
    justify-content: space-between;
    gap: 2rem;
}

.hero-left{
    width: 50%;
    display: flex;
    flex-direction: column;
    gap: 2rem; 
    justify-content: space-around;
    align-items: center;
    /* background-color: green; */
}
.hero-right{
    width: 50%;
    /* background-color: khaki; */
}
.hero-para-text{
    max-width: 15rem;
}

@media (max-width: 600px){
    .hero-section, .hero-left, .hero-right{
        text-align: center;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        width: 100%;
    }
}